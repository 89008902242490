import "./App.css";
import "@aws-amplify/ui-react/styles.css";

import { Authenticator, translations } from "@aws-amplify/ui-react";
import { Amplify, Auth, I18n } from "aws-amplify";
import React from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const config = window["runtimeConfig"];
config["graphql_headers"] = async () => ({
  // 認証情報にメールアドレスを含めるためにIDトークンでAppSyncにアクセスする
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});
Amplify.configure(config);

//日本語化
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

//サーバエラーメッセージの日本語化
import { vocabularies } from "./components/config/CognitoJapaneseList";
I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");

//設定ファイル
import { FILE_DOMAIN, TOP_URL } from "./components/config/conf";

function App() {
  chkElm4root();
  return (
    <Authenticator hideSignUp={true}>
        <div className="App">
          <header className="App-header">
          </header>
        </div>
    </Authenticator>
  );
}

export default App;

/**
 * root要素の監視
 */
function chkElm4root(){
  const config = {
    attribute: true,
    childList: true,
    subtree: true
  }

  const elmRoot = document.getElementById("root");
  if(elmRoot){
    const observer = new MutationObserver(chkElm4App);
    observer.observe(elmRoot, config)
  }
}

/**
 * root要素変化後の処理
 */
function chkElm4App(){
  const elmApp = document.getElementsByClassName("App");
  if(elmApp && elmApp.length > 0){
    loadUrl();
  }
}

/**
 * ログイン後の遷移先
 */
function loadUrl(){
  window.location.href = `${FILE_DOMAIN}${TOP_URL}`; //conf.jsで定義
}